export class Colors {
  primary: string = '#FF5500';
  primary_dark: string = '#FF5500';
  secondary: string = '#8CC83C';
  danger: string = '#f53d3d';
  warning: string = '#FFCC00';
  light: string = '#f8f8f8';
  gray: string = '#989aa2';
  dark: string = '#222428';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#FF5500';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Fjordkraft';
  appID: string = '';
  appHost: string = 'lading.fjordkraft.no';
  showTitle: boolean = false;
  defaultLang: string = 'nb';
  appToken: string = '064034d5-40ae-4f7b-a54a-3ee1650a27af';
  root: string = 'https://fjordkraftapp.smartcharge.io';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  pushAppID: string = '';
  pushSenderID: string = '';
  stationIcon: StationIcon = { height: 45, width: 33 };
  availableLangs: string[] = ['nb', 'en'];
  toolbarLogoHeight: string = '1.6em';
  privacyPolicy: any = {
    nb: 'https://www.fjordkraft.no/om-oss/personvern/',
    en: 'https://www.fjordkraft.no/om-oss/personvern/'
  };
  termsAndConditions: any = {
    nb: 'https://www.fjordkraft.no/om-oss/avtalevilkar/',
    en: 'https://www.fjordkraft.no/om-oss/avtalevilkar/'
  };
  contact: any = {
    external: true,

    email: '',
    phone: '230 06109',
    web: 'https://www.fjordkraft.no/ladesmart',
    chat: '',
    customerService: {
      nb: 'https://www.fjordkraft.no/strom/kundeservice/',
      en: 'https://www.fjordkraft.no/strom/kundeservice/'
    }
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
