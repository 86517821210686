import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scCurrencySymbol'
})
export class CurrencySymbolPipe extends CurrencyPipe implements PipeTransform {
  override transform(value: string): string | null;
  override transform(value: null | undefined): null;
  override transform(value: string | null | undefined): string | null {
    if (!value || typeof value !== 'string') {
      return '';
    }

    const currencyValue = super.transform(0, value, 'symbol-narrow', '1.0-2');

    return currencyValue?.replace(/[0-9]/g, '') ?? '';
  }
}
