<ion-header [ngClass]="{ 'pushed-page': pushedPage }">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button color="toolbar-button" (click)="cancel(accessCard)">
        <span *ngIf="page !== 'auth' || accessCard">
          {{ 'APP.CLOSE' | translate }}
        </span>
        <span *ngIf="page === 'auth' && !accessCard">
          {{ 'AUTH.SKIP' | translate }}
        </span>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{
        accessCard
          ? ('ACCESS_CARDS.TITLE_UPDATE' | translate)
          : ('ACCESS_CARDS.TITLE_ADD' | translate)
      }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <span class="searchbar-container" *ngIf="!presetPoint && !scanning">
    <ion-searchbar
      placeholder="{{ 'STATIONS.SEARCH_BOTH' | translate }}"
      #searchbar
      (ionInput)="searchStations($event)"
      (ionCancel)="clearSearch()"
      (ionClear)="clearSearch()"></ion-searchbar>
  </span>
  <div class="ion-text-center" *ngIf="!scanning && !mustPlugFirst && point">
    <ion-spinner class="spinner-center" *ngIf="loading"></ion-spinner>
  </div>
  <span *ngIf="!scanning && !mustPlugFirst && !point">
    <span
      *ngIf="
        loading || (!loading && availableItems() && availableItems().length)
      ">
      <p class="ion-text-center ion-padding-horizontal">
        {{ 'ACCESS_CARDS.KNOW_WHERE_YOU_ARE' | translate }}
      </p>
      <h3
        class="ion-text-center ion-padding-horizontal pre-wrap"
        *ngIf="!showPoints">
        {{ 'ACCESS_CARDS.SELECT_THE_STATION' | translate }}
      </h3>
      <h3 class="ion-text-center ion-padding-horizontal" *ngIf="showPoints">
        {{ 'ACCESS_CARDS.SELECT_THE_BOX' | translate }}
      </h3>
    </span>

    <div
      class="no-available ion-text-center ion-padding"
      *ngIf="!loading && availableItems() && !availableItems().length">
      <h3>
        <ion-icon name="sad-outline" color="gray"></ion-icon>
        <br />
        {{ 'ACCESS_CARDS.NO_AVAILABLE_BOXES' | translate }}
      </h3>
      <p>
        {{ 'ACCESS_CARDS.TRY_GETTING_CLOSER' | translate }}
      </p>
      <ion-button
        expand="block"
        class="ion-margin-vertical"
        fill="clear"
        (click)="cancel(accessCard)">
        <span *ngIf="page !== 'auth' || accessCard">
          {{ 'APP.CLOSE' | translate }}
        </span>
        <span *ngIf="page === 'auth' && !accessCard">
          {{ 'AUTH.SKIP' | translate }}
        </span>
      </ion-button>
    </div>

    <ion-spinner class="spinner-center" *ngIf="loading"></ion-spinner>
    <ion-list class="items">
      <ng-container
        *ngIf="!searchValue && claimedPoints && claimedPoints.length">
        <ion-item-divider class="no-border" mode="md">
          {{
            (claimedPoints.length > 1
              ? 'MY_CHARGERS.TITLE_PLURAL'
              : 'MY_CHARGERS.TITLE'
            ) | translate
          }}
        </ion-item-divider>
        <ion-item
          button
          [detail]="!item.State || item.State !== 'Offline'"
          (click)="openOrScan(item)"
          *ngFor="let item of claimedPoints">
          <ion-icon
            *ngIf="!item.PK_ChargePointID"
            name="location-outline"
            slot="start"></ion-icon>
          <ion-icon
            *ngIf="item.PK_ChargePointID"
            name="wifi-outline"
            slot="start"></ion-icon>
          <ion-label>
            <h2 class="station-name">
              {{ item.Name }}
              <span *ngIf="item.ChargerCode && item.Name !== item.ChargerCode">
                ({{ item.ChargerCode }})
              </span>
            </h2>
            <h3 *ngIf="item.Address">{{ item.Address }}</h3>
            <h3 *ngIf="item.PK_ChargePointID">{{ item.StationName }}</h3>
          </ion-label>
          <ion-note
            class="state offline-box"
            *ngIf="item.State && item.State === 'Offline'">
            {{ 'BOX_LIST.OFFLINE' | translate }}
          </ion-note>
        </ion-item>
        <ion-item-divider mode="md" class="no-border ion-margin-top">
          {{ 'STATIONS.STATIONS_NEARBY' | translate }}
        </ion-item-divider>
      </ng-container>
      <ion-item
        button
        [detail]="!item.State || item.State !== 'Offline'"
        (click)="openOrScan(item)"
        *ngFor="let item of availableItems()">
        <ion-icon
          *ngIf="!item.PK_ChargePointID"
          name="location-outline"
          slot="start"></ion-icon>
        <ion-icon
          *ngIf="item.PK_ChargePointID"
          name="wifi-outline"
          slot="start"></ion-icon>
        <ion-label>
          <h2 class="station-name">
            {{ item.Name }}
            <span *ngIf="item.ChargerCode && item.Name !== item.ChargerCode">
              ({{ item.ChargerCode }})
            </span>
          </h2>
          <h3 *ngIf="item.Address">{{ item.Address }}</h3>
          <h3 *ngIf="searchedItems.length && item.PK_ChargePointID">
            {{ item.StationName }}
          </h3>
        </ion-label>
        <ion-note
          class="state offline-box"
          *ngIf="item.State && item.State === 'Offline'">
          {{ 'BOX_LIST.OFFLINE' | translate }}
        </ion-note>
      </ion-item>
    </ion-list>
  </span>
  <span
    *ngIf="mustPlugFirst && !scanning"
    class="vertical-content ion-padding-horizontal">
    <div>
      <h2 class="ion-text-center ion-padding-bottom">
        {{ 'ACCESS_CARDS.SPECIAL_REQUIREMENTS' | translate }}
      </h2>
      <h4 class="ion-text-center ion-padding-bottom">
        {{ 'ACCESS_CARDS.CONNECT_CABLE_BEFORE_SCAN' | translate }}
      </h4>
      <h1 *ngIf="point && point.ConnectorTypes" class="plug-icons">
        <i
          *ngFor="let conn of point.ConnectorTypes"
          class="con-{{ conn.IconName }}"></i>
      </h1>
      <ion-row class="ion-align-items-center">
        <ion-col class="ion-text-center">
          <ion-button color="primary" (click)="initScanning(point)">
            {{ 'APP.DONE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </span>
  <span *ngIf="scanning" class="vertical-content ion-padding-horizontal">
    <div>
      <span *ngIf="!accessCard">
        <div class="swipe-icon">
          <img src="assets/img/swipe-icon.png" />
        </div>
        <h1 class="ion-text-center ion-padding-bottom">
          {{ 'ACCESS_CARDS.YOU_MAY_SCAN' | translate }}
        </h1>
        <p class="ion-text-center ion-padding-bottom">
          {{ 'ACCESS_CARDS.RED_LIGHT' | translate }}
        </p>
        <p class="ion-text-center ion-padding-bottom">
          <ion-spinner name="dots"></ion-spinner>
          <br />
          {{ 'ACCESS_CARDS.WAITING_FOR_SCAN' | translate }}
        </p>
      </span>
      <span class="ion-text-center" *ngIf="accessCard">
        <div class="success-message">
          <svg
            class="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52">
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none" />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
          <h1>{{ 'APP.SUCCESS' | translate }}!</h1>
          <h2>{{ 'ACCESS_CARDS.CARD_IS_ADDED' | translate }}</h2>
          <p *ngIf="mustPlugFirst">
            {{ 'ACCESS_CARDS.RECONNECT_TO_START' | translate }}
          </p>
        </div>
        <ion-button expand="block" fill="clear" (click)="addName()">
          {{ 'ACCESS_CARDS.ADD_NAME' | translate }} ({{
            'APP.OPTIONAL' | translate
          }})
        </ion-button>
      </span>

      <ion-button
        expand="block"
        class="ion-margin-vertical"
        fill="clear"
        (click)="cancel(accessCard)">
        <span *ngIf="page !== 'auth' || accessCard">
          {{ 'APP.CLOSE' | translate }}
        </span>
        <span *ngIf="page === 'auth' && !accessCard">
          {{ 'AUTH.SKIP' | translate }}
        </span>
      </ion-button>
    </div>
  </span>
</ion-content>
