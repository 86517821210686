export class FilterClass {
  amp: MinMaxFilter = new MinMaxFilter(0, 100);
  kWh: MinMaxFilter = new MinMaxFilter(3.6, 50);
  volt: MinMaxFilter = new MinMaxFilter(0, 400);
  connectors: Array<string> = [];
  smart: boolean = true;
  range: number = 20;
  search: string = '';
}
class MinMaxFilter {
  lower?: number;
  upper?: number;
  constructor(min?: number, max?: number) {
    this.lower = min;
    this.upper = max;
  }
}
