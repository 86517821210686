import { Injectable } from '@angular/core';
import { Subscription, Observable, map } from 'rxjs';
import { Platform } from '@ionic/angular';
import { GlobalsService } from '../globals/globals.service';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { UserService } from '../user/user.service';
import { LocationService, LocationClass } from '../location/location.service';
import { DistanceService } from '../distance/distance.service';
import { FilterService } from '../filter/filter.service';
import { AppConfig } from 'src/app/variables';
import {
  ConnectorType,
  CreateConnector
} from 'src/app/components/installer/installer.type';

@Injectable({
  providedIn: 'root'
})
export class StationsService {
  stations: any[] = []; //Holding filtered stations
  mapStations: any[] = []; //Holding stations for the map
  mapRequest: any;
  boxes: any;
  points: any;
  mmConnectors: any;
  connectorTypes: any;
  externalStations: boolean = true;
  stationType: string = 'smartcharge';
  log: string = '';
  reload: boolean = false;
  loading: boolean = true; //Loading stations
  limit: number = 30;
  appConfig: AppConfig = new AppConfig();
  lastMapCall: Subscription;
  selectedStation: any = null;

  constructor(
    private platform: Platform,
    private gf: GlobalsService,
    private _http: HttpInterceptorService,
    private userService: UserService,
    private locationService: LocationService,
    private distanceService: DistanceService,
    private filterService: FilterService
  ) {
    this.externalStations = true;
  }
  load(reload?: boolean, scroll?: boolean): Promise<any> {
    this.loading = true;
    this.reload = reload;

    if (reload) {
      this.stations = [];
    }

    if (scroll) {
      this.limit += 30;
      this.filterService.filters.range += 1;
    }

    return new Promise((resolve) => {
      this.platform.ready().then(() => {
        this.userService.getCustomerID().then((customerID) => {
          this.getStations(customerID).then(
            (stations) => {
              stations = stations.filter((station: any) => {
                if (
                  station.Name &&
                  !station.Name.toUpperCase().includes('OSLOTECH') &&
                  !station.Name.toUpperCase().includes('PRESTEGÅRDSVEIEN 8')
                ) {
                  return station;
                }
              });

              this.update(stations);
              this.reload = false;
              this.loading = false;

              resolve(this.stations);
            },
            (err) => {
              this.loading = false;
              this.gf.errorLog(err);
            }
          );
        });
      });
    });
  }
  getSummary(stationID) {
    return this._http.get('StationGroupServices/GetSummary/' + stationID);
  }
  getDefaultPricing(stationID) {
    return this._http.get(
      `ServicePricingFactors/GetDefaultPricingFactor/${stationID}`
    );
  }
  hasOtherPrices(summary): boolean {
    if (!summary) {
      return false;
    }

    return Object.entries(summary).some((keyPair: any) => {
      const value = keyPair[1];
      return (
        value != null &&
        value.Description != null &&
        value.Description.length > 0
      );
    });
  }

  sortStations() {
    this.stations.sort((locationA, locationB) => {
      return locationA.Distance - locationB.Distance;
    });
  }
  getStationsMap(bounds, center): Observable<any> {
    this.loading = true;

    return Observable.create((observer) => {
      if (this.lastMapCall && !this.lastMapCall.closed) {
        this.lastMapCall.unsubscribe();
      }

      let start = bounds.ne; //{lat: bounds.f.b, lng: bounds.b.f}
      let end = bounds.sw; //{lat: bounds.f.f, lng: bounds.b.b};

      let radius =
        this.distanceService.getDistanceBetweenPoints(start, end, 'km') / 2;
      radius = Math.ceil(radius);

      let includeExt = this.filterService.filters.smart ? false : true;
      let minKWh =
        this.filterService.filters.kWh.lower === 3.6
          ? 0
          : this.filterService.filters.kWh.lower;
      let maxKWh =
        this.filterService.filters.kWh.upper === 50
          ? 1000
          : this.filterService.filters.kWh.upper;

      this.userService.getCustomerID().then((customerID) => {
        //{customerID}/{limit}/{lat}/{lon}/{range}/{startIndex}/{loadBoxes}/{minKWh}/{maxKWh}/{includeExternalStations}/{cTypes}/{appToken}
        let stationString =
          'ChargingStations/ForCustomer/' +
          customerID +
          '/' +
          this.limit +
          '/' +
          center.latitude +
          '/' +
          center.longitude +
          '/' +
          radius +
          '/0/false/' +
          minKWh +
          '/' +
          maxKWh +
          '/' +
          includeExt +
          '/%A0/' +
          this.appConfig.appToken;
        if (customerID && customerID !== 0) {
          this.lastMapCall = this._http.get(stationString).subscribe(
            (stations) => {
              this.handleMapStations(stations);
              observer.next(stations);
              observer.complete();
            },
            (err) => {
              this.loading = false;
              observer.error(err);
              observer.complete();
            }
          );
        } else {
          this.lastMapCall = this._http.getUnsecure(stationString).subscribe(
            (stations) => {
              this.handleMapStations(stations);
              //resolve(stations);
              observer.next(stations);
              observer.complete();
            },
            (err) => {
              //reject(err);
              this.loading = false;
              observer.error(err);
              observer.complete();
            }
          );
        }
      });
    });
  }
  handleMapStations(stations) {
    if (!stations || !stations.length) return;

    stations.forEach((station) => {
      let index = this.mapStations.findIndex(
        (item) => item.PK_ChargingStationID === station.PK_ChargingStationID
      );
      if (index > -1) {
        //Update
        this.mapStations[index] = station;
      } else {
        //Add
        this.mapStations.push(station);
      }
    });
    this.loading = false;
    this.mapStations = this.mapStations.slice();
  }
  getStations(
    customerID,
    loadBoxes: boolean = false,
    noFilter: boolean = false
  ): Promise<any> {
    return new Promise((resolve, reject?) => {
      this.locationService.getUserLocation().then((location: LocationClass) => {
        let range = this.filterService.filters.range;
        let loc = location.latitude + '/' + location.longitude;

        let includeExt = this.filterService.filters.smart ? false : true;
        let minKWh =
          this.filterService.filters.kWh.lower === 3.6
            ? 0
            : this.filterService.filters.kWh.lower;
        let maxKWh =
          this.filterService.filters.kWh.upper === 50
            ? 1000
            : this.filterService.filters.kWh.upper;

        if (noFilter) {
          range = 5;
          includeExt = false;
          minKWh = 0;
          maxKWh = 1000;
        }

        //{customerID}/{limit}/{lat}/{lon}/{range}/{startIndex}/{loadBoxes}/{minKWh}/{maxKWh}/{includeExternalStations}/{cTypes}/{appToken}
        let stationString =
          'ChargingStations/ForCustomer/' +
          customerID +
          '/' +
          this.limit +
          '/' +
          loc +
          '/' +
          range +
          '/0/' +
          loadBoxes +
          '/' +
          minKWh +
          '/' +
          maxKWh +
          '/' +
          includeExt +
          '/%A0/' +
          this.appConfig.appToken;
        if (customerID && customerID !== 0) {
          this._http.get(stationString).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
        } else {
          this._http.getUnsecure(stationString).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
        }
      });
    });
  }
  getStationByPointId(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getCustomerID().then((customerID) => {
        let request =
          'ChargingStations/byPoint/' + id + '/' + this.appConfig.appToken;
        if (customerID && customerID !== 0) {
          this._http.get(request).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
        } else {
          this._http.getUnsecure(request).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          );
        }
      });
    });
  }
  getStation(id): Promise<any> {
    return new Promise((resolve) => {
      this.userService.getCustomerID().then((customerID) => {
        let request =
          'ChargingStations/' + id + '/false/' + this.appConfig.appToken;
        if (customerID && customerID !== 0) {
          this._http.get(request).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              resolve(null);
            }
          );
        } else {
          this._http.getUnsecure(request).subscribe(
            (res) => {
              resolve(res);
            },
            (err) => {
              resolve(null);
            }
          );
        }
      });
    });
  }
  getStationSettings(stationID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.getUnsecure('ChargingStationSettings/' + stationID).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (err) => {
          reject(err.errorName);
        }
      });
    });
  }

  update(stations) {
    this.stations = stations;
    this.sortStations();
  }
  updateStations() {
    this.locationService.getUserLocation().then((loc: LocationClass) => {
      this._http
        .get('ChargingStations/GetChargingStationsStatus')
        .subscribe((updatedValues) => {
          this.stations.map((station) => {
            updatedValues.find((newValue) => {
              if (
                newValue.PK_ChargingStationID === station.PK_ChargingStationID
              ) {
                //station.distance = newValue.Distance;
                station.availableBoxes = newValue.AvailableBoxes;
              }
            });
          });
        });
    });
  }
  logg(message) {
    this.log += message + '\n';
  }

  setFavoriteStation(customerId: number, stationId: number) {
    return this._http.post(
      `FavoriteLocations/SetFavoriteChargingStation/${customerId}/${stationId}`,
      {}
    );
  }

  removeFavoriteStation(customerId: number, stationId: number) {
    return this._http.delete(
      `FavoriteLocations/FavoriteChargingStation/${customerId}/${stationId}`
    );
  }

  getFavoriteLocations(customerId: number) {
    return this._http.get(
      `FavoriteLocations/${customerId}/${this.appConfig.appToken}`
    );
  }

  getFavoriteChargingStations(customerId: number) {
    return this._http.get(
      `FavoriteLocations/GetFavoriteChargingStations/${customerId}`
    );
  }

  getFavoriteCharginPoint(customerId: number) {
    return this._http.get(
      `FavoriteLocations/GetFavoriteChargePoints/${customerId}`
    );
  }

  setFavoriteCharginPoint(customerId: number, chargePointId: number) {
    return this._http.post(
      `FavoriteLocations/SetFavoriteChargePoint/${customerId}/${chargePointId}`,
      {}
    );
  }

  removeFavoriteCharginPoint(customerId: number, chargePointId: number) {
    return this._http.delete(
      `FavoriteLocations/FavoriteChargePoint/${customerId}/${chargePointId}`
    );
  }

  getStationsForInstaller(customerID: number, start = 0, limit = 10) {
    return this._http.get(
      `ChargingStations/GetChargingStationsByCustomer/${customerID}/${limit}/0/${start}/ID/Descending/`
    );
  }

  getChargingBoxManufacturers() {
    return this._http.get(`/ChargingBoxManufacturers`);
  }

  getAvailableChargingChargerModels() {
    return this._http.get(`/ChargingBoxModels/GetChargingBoxModelsICanAdd`);
  }

  getWallSettingsByLocation(locationID: number) {
    return this._http.get(`WallSettings/ByStation/${locationID}`);
  }

  getConnectorTypes(): Observable<ConnectorType[]> {
    return this._http
      .get('CPConnectorTypes')
      .pipe(map((res) => res || ([] as ConnectorType[])));
  }

  createConnector(connectorsToBeAdded: CreateConnector[]) {
    return this._http.post(
      'ChargingBoxes/PostChargingBox',
      connectorsToBeAdded
    );
  }
}
