import { Injectable } from '@angular/core';
import { Capacitor, registerPlugin } from '@capacitor/core';

import {
  ILiveActivityPlugin,
  LiveActivityStartParams,
  LiveActivityStopParams,
  LiveActivityUpdateParams
} from './live-activity.interface';

const _pluginName = 'LiveActivityPlugin';

const LiveActivityPlugin = registerPlugin<ILiveActivityPlugin>(_pluginName);

@Injectable({
  providedIn: 'root'
})
export class LiveActivityPluginService {
  async start(params: LiveActivityStartParams): Promise<string> {
    if (Capacitor.isPluginAvailable(_pluginName)) {
      return (await LiveActivityPlugin.start(params))?.activityId;
    }
  }

  async update(params: LiveActivityUpdateParams): Promise<void> {
    if (Capacitor.isPluginAvailable(_pluginName)) {
      return await LiveActivityPlugin.update(params);
    }
  }

  async stop(params: LiveActivityStopParams): Promise<void> {
    if (Capacitor.isPluginAvailable(_pluginName)) {
      return await LiveActivityPlugin.stop(params);
    }
  }
}
