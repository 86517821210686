import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DefaultsService } from '../defaults/defaults.service';
import { AuthService } from '../auth/auth.service';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  constructor(
    private http: HttpClient,
    private defaults: DefaultsService,
    private authService: AuthService,
    private loading: LoadingService
  ) {}

  getUnsecure(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    //To be used on api's that requires no authentication. Request will be denied if api is secure
    return this.http.get(this.defaults.baseUrl + url, options).pipe(
      map((res) => {
        return this.extractData(res, url, 'GET_UNSECURE');
      }),
      catchError((err) => {
        return this.handleError(new APIError(err, url, 'GET_UNSECURE'));
      })
    );
  }
  get(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.http.get(this.defaults.baseUrl + url, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'GET');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'GET'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' }, url, 'GET')
          );
        }
      })
    );
  }
  getNonJson(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';

    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        //authenticated = false;
        if (authenticated) {
          return this.http
            .get(this.defaults.baseUrl + url, options)
            .pipe(catchError(this.handleError));
        } else {
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' })
          );
          //throw Observable.throw('Unable to re-authenticate');
        }
      })
    );
  }

  getNonJsonUnsecure(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.http
      .get(this.defaults.baseUrl + url, options)
      .pipe(catchError(this.handleError));
  }

  postUnsecure(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.http.post(this.defaults.baseUrl + url, body, options).pipe(
      map((res) => {
        return this.extractData(res, url, 'POST_UNSECURE');
      }),
      catchError((err) => {
        return this.handleError(new APIError(err, url, 'POST_UNSECURE'));
      })
    );
  }
  post(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.http
            .post(this.defaults.baseUrl + url, body, options)
            .pipe(
              map((res) => {
                return this.extractData(res, url, 'POST');
              }),
              catchError((err) => {
                return this.handleError(new APIError(err, url, 'POST'));
              })
            );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'POST'
            )
          );
        }
      })
    );
  }
  //Put is always secure
  put(url: string, body: any, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.http.put(this.defaults.baseUrl + url, body, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'PUT');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'PUT'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError({ errorName: 'Unable to re-authenticate' }, url, 'PUT')
          );
        }
      })
    );
  }

  //Patch is always secure
  patch<T = any>(url: string, body: any, options: any = {}): Observable<T> {
    if (!options.observe) options.observe = 'response';
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.http
            .patch(this.defaults.baseUrl + url, body, options)
            .pipe(
              map((res) => {
                return this.extractData(res, url, 'PATCH');
              }),
              catchError((err) => {
                return this.handleError(new APIError(err, url, 'PATCH'));
              })
            );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'PATCH'
            )
          );
        }
      })
    );
  }

  //Delete is always secure
  delete(url: string, options: any = {}): Observable<any> {
    if (!options.observe) options.observe = 'response';
    return this.authService.authenticate().pipe(
      mergeMap((authenticated) => {
        if (authenticated) {
          return this.http.delete(this.defaults.baseUrl + url, options).pipe(
            map((res) => {
              return this.extractData(res, url, 'DELETE');
            }),
            catchError((err) => {
              return this.handleError(new APIError(err, url, 'DELETE'));
            })
          );
        } else {
          //Prompt login.
          //this.authService.promptAuth();
          //This only happens if user does not have a refresh-token, and that can only be received after login/registration
          return this.handleError(
            new APIError(
              { errorName: 'Unable to re-authenticate' },
              url,
              'DELETE'
            )
          );
        }
      })
    );
  }
  private extractData(res, url, type: apiType) {
    if (res.status === 200) {
      if (res.body && res.body.Result) {
        res = res.body.Result;
      } else if (res.body && !res.body.Result) {
        res = res.body;
      }

      if (res.errorName) {
        throw new APIError(res, url, type);
      } else {
        //All good
        return res;
      }
    } else {
      throw new APIError(res, url, type);
    }
    /*
        if(res.Result){
            res = res.Result;
        }

        if(res.errorName){
            throw new APIError(res, url, type);
        }else{
            return res;
        }*/
  }
  handleError(err: APIError): Observable<any> {
    this.loading.dismiss();
    return throwError(new APIError(err));
    // return throwError(err);
  }
}
class APIError {
  success?: boolean = false;
  errorName?: string = 'Unknown error';
  error?: number;
  datas?: any;
  api?: string;
  type?: apiType;
  constructor(err?: any, _api?: string, _type?: apiType) {
    this.api = (_type ? _type + ' ' : ' ') + _api;
    this.type = _type;

    if (err) {
      if (err.error && err.error.Message) {
        err.errorName = err.error.Message;
      } else if (err.error && err.error.datas && err.error.datas.Message) {
        err.errorName = err.error.datas.Message;
      } else if (!err.errorName) {
        err.errorName = err.statusText;
      }
      Object.assign(this, err);
    }
  }
}
type apiType =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'GET_UNSECURE'
  | 'POST_UNSECURE'
  | 'PATCH';
