import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Geolocation, Position, PositionOptions } from '@capacitor/geolocation';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  lastGPS: LocationClass;
  lastCenter: LocationClass = new LocationClass();
  private watchingID: any;
  constructor(
    private http: HttpClient,
    private platform: Platform,
    private storage: Storage,
    private _http: HttpInterceptorService
  ) {}
  public getUserLocation(): Promise<LocationClass> {
    /*
            Getting user location.
            First priority is gps.
            Then it should check for last known position
            -- Fallback to IP-location if that doesn't work
        */
    return new Promise((resolve, reject) => {
      this.platform.ready().then(
        () => {
          //Checking if mobile phone
          if (this.platform.is('capacitor')) {
            this.getGPSLocation().then(
              (location) => {
                resolve(location);
              },
              (err) => {
                reject(err);
              }
            );
          } else {
            //Web-app, no need to check for enabled location because user must be connected to the Internet

            this.getGPSLocation().then(
              (location) => {
                resolve(location);
              },
              (err) => {
                reject(err);
              }
            );
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  public watchLocation(): Observable<Position> {
    return new Observable((observer) => {
      this.stopWatching();
      this.watchingID = Geolocation.watchPosition(
        {
          maximumAge: 60000,
          timeout: 5000
        },
        (position: Position) => {
          observer.next(position);
          observer.complete();
        }
      );
    });
  }
  public stopWatching() {
    if (this.watchingID) {
      Geolocation.clearWatch({ id: this.watchingID });
      this.watchingID = undefined;
    }
  }
  private getGPSLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        this.lastKnownLocation().then((location) => {
          if (location) {
            resolve(location);
          } else {
            this.ipLocation().then(
              (res) => {
                resolve(res);
              },
              (err) => {
                reject(err);
              }
            );
          }
        });
      } else {
        this.getCurrentPosition().then(
          (loc) => {
            resolve(loc);
          },
          (err) => {
            this.lastKnownLocation().then((location) => {
              if (location) {
                resolve(location);
              } else {
                this.ipLocation().then(
                  (res) => {
                    resolve(res);
                  },
                  (err) => {
                    reject(err);
                  }
                );
              }
            });
          }
        );
      }
    });
  }
  public getCurrentPosition(reload?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (reload) {
        this.lastKnownLocation().then((loc) => {
          if (loc) {
            resolve(loc);
          }
        });
      }

      if (!navigator.geolocation) {
        reject('Geolocation not available');
      } else {
        let options: PositionOptions = {
          enableHighAccuracy: false,
          maximumAge: 60000,
          timeout: 5000
        };

        Geolocation.getCurrentPosition(options).then(
          (location: Position) => {
            //The optimal run

            this.lastGPS = new LocationClass(
              location.coords.latitude,
              location.coords.longitude,
              true
            );

            this.storage.set('location', JSON.stringify(this.lastGPS));
            resolve(this.lastGPS);
          },
          (err) => {
            console.log(err);
            reject(err.message);
          }
        );
      }
    });
  }
  private lastKnownLocation(): Promise<any> {
    /*
            Getting the last known location of the user
            Either from current session or from storage
        */
    return new Promise((resolve) => {
      if (this.lastGPS) resolve(this.lastGPS);

      this.storage.get('location').then((location) => {
        location ? resolve(JSON.parse(location)) : resolve(null);
      });
    });
  }
  private ipLocation(): Promise<any> {
    /*
            Fallback function for retrieving ip-location if everything else fails
        */
    return new Promise((resolve, reject) => {
      this.getIPInfo().then(
        (res) => {
          if (res.latitude && res.longitude) {
            //Not storing this location to lastGPS because it is not good
            resolve(new LocationClass(res.latitude, res.longitude, false));
          } else {
            //Only when absolutely everything fails

            reject('Could not retrieve location');
          }
        },
        (err) => {
          reject('Could not retrieve location');
        }
      );
    });
  }
  getCountry(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('Countries/GetAll').subscribe((countries) => {
        countries = countries.datas;

        //By default this will get last known location, but also update it
        this.getCurrentPosition(true).then(
          (loc: LocationClass) => {
            //Get country from lat, lng
            let noResults = true;
            this.http
              .get<any>(
                'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                  loc.latitude +
                  ',' +
                  loc.longitude +
                  '&key=AIzaSyBNAJJ0c9-VScOE6H78bO-pWqKBAwqZg7E'
              )
              .subscribe(
                (res) => {
                  for (let result of res.results) {
                    for (let comp of result.address_components) {
                      if (comp.types.includes('country')) {
                        let country = countries.filter(
                          (country) =>
                            country.alpha2CountryCode.trim() === comp.short_name
                        )[0];
                        if (country) {
                          noResults = false;
                          resolve(country);
                          break;
                        }
                      }
                    }
                  }
                  if (noResults) {
                    //Fallback
                    this.getIPInfo(3000).then(
                      (res) => {
                        if (res.country_code) {
                          let country = countries.filter(
                            (country) =>
                              country.alpha2CountryCode.trim() ===
                              res.country_code
                          )[0];
                          resolve(country);
                        } else {
                          reject('countryNotFound');
                        }
                      },
                      (err) => {
                        reject();
                      }
                    );
                  }
                },
                (err) => {
                  this.getIPInfo(3000).then(
                    (res) => {
                      if (res.country_code) {
                        let country = countries.filter(
                          (country) =>
                            country.alpha2CountryCode.trim() ===
                            res.country_code
                        )[0];
                        resolve(country);
                      } else {
                        reject('countryNotFound');
                      }
                    },
                    (err) => {
                      reject();
                    }
                  );
                }
              );
          },
          (err) => {
            this.getIPInfo(3000).then(
              (res) => {
                if (res.country_code) {
                  let country = countries.filter(
                    (country) =>
                      country.alpha2CountryCode.trim() === res.country_code
                  )[0];
                  resolve(country);
                } else {
                  reject('countryNotFound');
                }
              },
              (err) => {
                reject();
              }
            );
          }
        );
      });
    });
  }
  getIPInfo(timeout?): Promise<any> {
    return new Promise((resolve, reject) => {
      let timer = 0;
      let interval;

      let request = this._http.getUnsecure('UserPreferences/GetIP').subscribe(
        (res) => {
          if (res.datas && res.success) {
            resolve(res.datas);
          } else {
            reject();
          }
          if (timeout) clearInterval(interval);
        },
        (err) => {
          if (timeout) clearInterval(interval);
          reject();
        }
      );
      if (timeout) {
        interval = setInterval(() => {
          timer += 10;
          if (timer >= timeout) {
            request.unsubscribe();
            clearInterval(interval);
            reject('timeout');
          }
        }, 10);
      }
    });
  }

  getAddressByLocation(latitude: number, longitude: number) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBNAJJ0c9-VScOE6H78bO-pWqKBAwqZg7E`
    );
  }

  getAllCountries() {
    return this._http.get('Countries/GetAll').pipe(map((res) => res.datas));
  }

  makeAddress(googleGeoCodeRespone: any): FormattedAddress {
    let address: FormattedAddress = {
      streetName: '',
      streetNumber: '',
      town: '',
      state: '',
      postalCode: '',
      country: '',
      countrycode: ''
    };
    if (googleGeoCodeRespone?.results?.length) {
      // Loop through each results
      for (let result of googleGeoCodeRespone.results) {
        // Loop through each component
        result?.address_components.forEach((component) => {
          // Find address line by type
          component?.types?.forEach((type) => {
            switch (type) {
              case 'street_number':
                if (!address.streetNumber)
                  address.streetNumber = component.long_name;
                break;
              case 'route':
                if (!address.streetName)
                  address.streetName = component.long_name;
                break;
              case 'postal_town':
                if (!address.town) address.town = component.long_name;
                break;
              case 'administrative_area_level_1':
                if (!address.state) address.state = component.long_name;
                break;
              case 'country':
                if (!address.country) {
                  address.country = component.long_name;
                  address.countrycode = component.short_name.trim();
                }
                break;
              case 'postal_code':
                if (!address.postalCode)
                  address.postalCode = component.long_name;
                break;
            }
          });
        });
        // Break if completed address is found.
        if (
          address.streetNumber !== '' &&
          address.streetName !== '' &&
          address.town !== '' &&
          address.state !== '' &&
          address.postalCode !== '' &&
          address.country !== ''
        ) {
          break;
        }
      }
    }
    return address;
  }
}

export class LocationClass {
  latitude: number;
  longitude: number;
  trueGps: boolean = true;
  constructor(_latitude?: number, _longitude?: number, _trueGps?: boolean) {
    this.latitude = _latitude;
    this.longitude = _longitude;
    this.trueGps = _trueGps;
  }
}

export interface FormattedAddress {
  streetName: string;
  streetNumber: string;
  state: string;
  town: string;
  postalCode: string;
  country: string;
  countrycode: string;
}
