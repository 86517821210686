import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultsService } from '../defaults/defaults.service';
import { Platform } from '@ionic/angular';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private defaults: DefaultsService,
    private platform: Platform
  ) {}

  init() {
    //Called when app is loaded

    //Will not run analytics if in dev environment
    if (this.defaults.production) {
      this.getGeoInfo();
      this.isPaused();

      //Analytics that is only for phones
      if (this.platform.is('capacitor')) {
        this.getPermissions();
      }
    }
  }

  private getGeoInfo() {
    this.http.get<any>('https://ipinfo.io/geo').subscribe((res) => {
      //Check if country is same as before
      //Then send to api
      //OR do not run this at all if country is stored locally from before.
    });
  }
  private getPermissions() {
    let permissions = {
      location: false,
      locationAuthorized: false,
      locationAvailable: false,
      camera: false,
      notifications: false
    };

    setTimeout(() => {
      //Give it a second, then call the db
    }, 1000);
  }

  private isPaused() {
    document.addEventListener(
      'pause',
      () => {
        let date = new Date();
        this.storage.set('closed', date);
      },
      false
    );
    window.onbeforeunload = () => {
      let date = new Date();
      this.storage.set('closed', date);
    };
  }
}
