import { Injectable } from '@angular/core';
import { UserType, UserTypeStrings } from './user-roles';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage-angular';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  constructor(private storage: Storage) {}

  async hasHighlevelAccess(
    requiredRole: UserType | UserType[],
    exact: boolean = false
  ) {
    //If no specific role is required we allow access
    if (!requiredRole) return true;
    let userType = await this.getUserType();

    //Check if usertype exists and if it is a valid type
    if (!userType || typeof userType !== 'number') return false;

    if (Array.isArray(requiredRole)) {
      //Check if either of the roles has access
      return !!requiredRole.filter((singleRole) =>
        this.checkSingleAccess(singleRole, userType, exact)
      ).length;
    } else {
      return this.checkSingleAccess(requiredRole, userType, exact);
    }
  }

  private checkSingleAccess(
    requiredRole: UserType | UserTypeStrings,
    userType,
    exact: boolean = false
  ) {
    if (typeof requiredRole == 'string') {
      //Convert to enum if string type is provided
      requiredRole = UserType[requiredRole];
    }
    //Checks the access
    if (exact) {
      //Checks exact match
      return userType == requiredRole;
    } else {
      //Checks if included
      return (userType & requiredRole) == requiredRole;
    }
  }

  getUserType(): Promise<boolean | number> {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (!token) resolve(false);
        try {
          token = helper.decodeToken(token);
          if (!token.UserType) resolve(false);
          //FIX: Temporary to allow old UserTypes. Remove when both backend and frontend is synced
          if (isNaN(parseInt(token.UserType)))
            token.UserType = UserType[token.UserType];
          resolve(parseInt(token.UserType));
        } catch (err) {
          //Could not decode token, by default we don't give access
          resolve(false);
        }
      });
    });
  }
}
