import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HttpInterceptorService } from '../http-interceptor';

@Injectable({
  providedIn: 'root'
})
export class SpotpriceService {
  constructor(private _http: HttpInterceptorService) {}

  getDefaultPricing = (stationID: number) => {
    return this._http.getUnsecure(
      `ServicePricingFactors/GetDefaultPricingFactor/${stationID}`
    );
  };

  getSpotPriceData = (
    stationID: number,
    startTime: number = 0,
    endTime: number = 0
  ) => {
    return this._http
      .getUnsecure(
        `BiddingZones/PricesStation/${stationID}/${startTime}/${endTime}/true`
      )
      .pipe(
        map((response: SpotPrice[]) => {
          return response.map((priceItem) => {
            const hour = new Date(priceItem.Start).getHours();
            return {
              hour: `${hour < 10 ? `0${hour}` : hour}:00`,
              price: {
                spotPrice: priceItem.Price_PerKWH,
                currency: priceItem.Currency
              }
            } as SpotPriceItem;
          });
        })
      );
  };
}

export interface SpotPriceItem {
  hour: string;
  price: { spotPrice: number; originalSpotPrice: number; currency: number };
}
export interface SpotPrice {
  Currency: number;
  End: string;
  Price_PerKWH: number;
  Price_PerKWH_ExVAT: number;
  Start: number;
}
export interface DefaultPricing {
  AmpsMax_DefaultPrice: number;
  ChargingCostKWH: number;
  Coefficient_Price_MaxAmps: number;
  CostPrice: number;
  CostPriceVATPercentage: number;
  Cost_Charging_Time: number;
  Cost_Charging_Time_VATPercentage: number;
  Cost_Occupied: number;
  Cost_Occupied_VATPercentage: number;
  Currency: string;
  FK_ChargingStationGroupServiceID: number;
  FK_ServiceID: number;
  FixedFeeOnTopPerKWH: number;
  FixedStartupCost: number;
  FixedStartupCostVATPercentage: number;
  HasVariablePricing: boolean;
  Name: string;
  OperatorCutOnTop: boolean;
  PK_ServicePricingFactorID: number;
  PercentageFeeOnTopPerKWH: number;
  SpeedMaxAmps: number;
  Type_Cost_Charging: string;
  Type_Cost_Charging_Time: string;
  Type_Cost_Occupied: string;
}
