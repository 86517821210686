import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TokenService } from '../token/token.service';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser: any;
  loggedIn$: Observable<boolean> = new Observable<boolean>();

  constructor(private tokenService: TokenService) {}

  authenticate(): Observable<boolean> {
    //Checks if token is expired, gets new one if it is
    if (
      this.tokenService.token &&
      !helper.isTokenExpired(this.tokenService.token)
    ) {
      //Token not expired, return true
      //return Observable.of(true);
      return Observable.create((observer) => {
        observer.next(true);
        observer.complete();
      });
    } else {
      //Note: If we aren't using rToken, return false here
      return Observable.create((observer) => {
        this.tokenService.refreshToken().then(
          () => {
            //Token refreshed, return true
            observer.next(true);
            observer.complete();
          },
          () => {
            //Could not refresh, return false
            observer.next(false);
            observer.complete();
          }
        );
      });
    }
  }
}
