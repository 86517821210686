import { Inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from '@smartcharge/shared/core/config';

import { AppConfig } from 'src/app/variables';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private storage: Storage,
    private translate: TranslateService
  ) {}

  async getLanguage(): Promise<string> {
    let lang;

    try {
      lang = await this.storage.get('lang');

      if (!lang || !this.appConfig.availableLangs.includes(lang)) {
        lang = this.appConfig.defaultLang;

        await this.storage.set('lang', lang);
      }
    } catch {
      // If storage doesn't work for some reason, use default
      lang = this.appConfig.defaultLang;
    }

    return lang;
  }

  ready(): Promise<any> {
    return new Promise((resolve) => {
      const stream = this.translate.stream('APP').subscribe((res) => {
        //Check if translations are loaded. Loading APP translations and checks if it is object
        if (typeof res === 'object') {
          resolve(true);
          stream.unsubscribe();
        }
      });
    });
  }
}
