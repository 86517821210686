import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect, signOut, getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { smartChargeUserSync } from './queries';
import { Hub } from 'aws-amplify/utils';

const client = generateClient();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'eu-west-1_4VBYn5OoK',
      // userPoolName: "UserPool6BA7E5F2",
      userPoolClientId: '3v5t3adanu1lk42e64ntfinvsn',
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: 'abra-app-prod-1.auth.eu-west-1.amazoncognito.com',
          scopes: ['email', 'openid', 'phone'],
          redirectSignIn: ['https://charge.abralife.com'],
          redirectSignOut: ['https://charge.abralife.com'],
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
    }
  },
  API: {
    GraphQL: {
      endpoint: 'https://api.prod.abralife.com/graphql',
      region: 'eu-west-1',
      defaultAuthMode: 'userPool'
    }
  }
});

Hub.listen('auth', async ({ payload }) => {
  if (payload.event === 'signInWithRedirect') {
    window.location.replace(`/stations?oauth=abra`);
  }
});

@Injectable({
  providedIn: 'root'
})
export class AbraAuthService {
  abraUser = null;
  constructor() {}

  loginWithAbra() {
    return new Promise((resolve) => {
      const user = this.checkCurrentAbraUser();
      resolve(user);
    });
  }

  async checkCurrentAbraUser() {
    try {
      await getCurrentUser();
      const user = await this.fetchUserDetails();
      return user;
    } catch (error) {
      this.abraUser = null;
      console.log('Not signed in');
      signInWithRedirect();
      return null;
    }
  }

  isLoggedInWithAbra() {
    return new Promise(async (resolve) => {
      try {
        await getCurrentUser();
        resolve(true);
      } catch (error) {
        resolve(false);
      }
    });
  }

  async fetchUserDetails() {
    if (this.abraUser) return this.abraUser;
    try {
      const response: any = await client.graphql({
        query: smartChargeUserSync
      });
      return (
        JSON.parse(response?.data?.smartChargeUserSync?.payload)?.Result || null
      );
    } catch (err) {
      this.logOutAbraUser();
      return null;
    }
  }

  async logOutAbraUser() {
    await signOut();
  }
}
