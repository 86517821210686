import * as Sentry from 'sentry-cordova';

// Needs this when building to get source map
// ionic build --prod --source-map
export function initSentry(env: string, version: string) {
  Sentry.init({
    release: version,
    environment: env,
    dsn: 'https://10abb3f536d443b1b36cdd35e495f0d1@o4504163250995200.ingest.sentry.io/4504293698437120'
  });
}
