import { Injectable } from '@angular/core';
import { AppLauncher } from '@capacitor/app-launcher';
import { Capacitor } from '@capacitor/core';
import { BackgroundColor, InAppBrowser } from '@capgo/inappbrowser';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController
  ) {}

  hasSupport(station) {
    if (!station || !station.SupportInformation) return false;

    let support = station.SupportInformation;
    if (
      !support.PhoneNumberString &&
      !support.SupportChat &&
      !support.SupportEmail &&
      !support.SupportCustomerServiceURL
    )
      return false;
    return true;
  }
  openSupport(station, showOperatorName?) {
    let buttons = [];
    let support = station.SupportInformation;

    if (support.PhoneNumberString) {
      buttons.push({
        text: support.PhoneNumberString, //this.translate.instant("SUPPORT.CALL"),
        icon: 'call-outline',
        handler: () => {
          window.open(
            'tel:' + support.PhoneNumberString,
            '_system',
            'hidden=yes,location=no'
          );
        }
      });
    }
    if (support.SupportChat) {
      buttons.push({
        text: this.translate.instant('SUPPORT.CHAT'),
        icon: 'chatbubbles-outline',
        handler: () => {
          if (Capacitor.isNativePlatform()) {
            InAppBrowser.openWebView({
              url: support.SupportChat,
              title: '',
              backgroundColor: BackgroundColor.WHITE
            });
          } else {
            AppLauncher.openUrl({ url: support.SupportChat });
          }
        }
      });
    }
    if (support.SupportEmail) {
      buttons.push({
        text: support.SupportEmail,
        icon: 'mail-outline',
        handler: () => {
          window.open(
            'mailto:' + support.SupportEmail,
            '_system',
            'hidden=yes,location=no'
          );
        }
      });
    }
    if (support.SupportCustomerServiceURL) {
      buttons.push({
        text: this.translate.instant('SUPPORT.SUPPORT_CENTER'),
        icon: 'help-circle-outline',
        handler: () => {
          if (Capacitor.isNativePlatform()) {
            InAppBrowser.openWebView({
              url: support.SupportCustomerServiceURL,
              title: '',
              backgroundColor: BackgroundColor.WHITE
            });
          } else {
            AppLauncher.openUrl({ url: support.SupportCustomerServiceURL });
          }
        }
      });
    }

    buttons.push({
      text: this.translate.instant('APP.CANCEL'),
      icon: 'close-outline',
      role: 'cancel'
    });
    this.actionSheetCtrl
      .create({
        header:
          this.translate.instant('SUPPORT.TITLE') +
          (station.OperatorName && showOperatorName
            ? ' (' + station.OperatorName + ')'
            : ''),
        buttons: buttons
      })
      .then((sheet) => {
        sheet.present();
      });
  }
}
