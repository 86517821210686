import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { GlobalsService } from '../globals/globals.service';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/variables';
import { map, of } from 'rxjs';
import moment from 'moment-timezone';

export const DEFAULT_CURRENCY = 'NOK';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  appConfig: AppConfig = new AppConfig();

  constructor(
    private alertCtrl: AlertController,
    public gf: GlobalsService,
    private _http: HttpInterceptorService,
    private translate: TranslateService
  ) {}

  redirectPath: string | null = null;

  // Being explicit
  setRedirectPath(path: string) {
    this.redirectPath = path;
  }

  getPossiblePaymentMethodsForPoint(point?) {
    const isFree = this.isFree(point);
    if (isFree) return of([]);
    return this._http.getUnsecure(
      `PaymentMethods/PossiblePaymentMethodsForAdhocPoint/${point.PK_ChargePointID}/${this.appConfig.appToken}`
    );
  }

  createBraintreeCustomer(
    braintreeObj,
    customer,
    currency,
    userType = ''
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      var newCustomer = {
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        CustomerID: customer.PK_CustomerID,
        Email: customer.Email, //Remove this when the prod-api is updated to accept CustomerID instead
        NonceFromClient: braintreeObj.nonce,
        Identifyer: braintreeObj.details.lastTwo || braintreeObj.details.email,
        PaymentType: braintreeObj.details.cardType || 'PayPal',
        Currency: currency,
        FK_PaymentMethodID: braintreeObj?.paymentMethodID
      };

      this._http
        .post(
          `PaymentMethods/AddPaymentMethodFromBraintree${userType}`,
          newCustomer
        )
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }
  getReservations(customerID: number, currency: string) {
    return this._http.get(
      `AdHoc/reservations/customer/${customerID}/${currency}`
    );
  }

  clearPayter(pointID: number) {
    return this._http.post(`Terminal/payter/cancel/${pointID}`, {});
  }

  createPaymentMethod(customerID, methodID, isDefault = true) {
    return this._http.post('PaymentMethods/AddPaymentMethod', {
      PaymentMethodTypeID: methodID,
      CustomerID: customerID,
      IsDefault: isDefault
    });
  }
  checkVerification(customerID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get('PaymentMethods/IsDefaultCard3DSecure/' + customerID)
        .subscribe(
          (res) => {
            if (res.datas) {
              resolve(res.datas);
            } else {
              resolve(null);
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  reserve(userID, pointID, paymentMethod: any) {
    return this._http.post(`AdHoc/reserve/start`, {
      Redirect: paymentMethod?.RedirectURL,
      UserID: userID,
      PointID: pointID,
      PaymentType: paymentMethod.PaymentType
    });
  }
  finishReservation(transactionID) {
    return this._http.post(`AdHoc/reserve/finish/${transactionID}`, {});
  }
  initAdhoc(userLang?: string) {
    let postRegister = {
      CreationReason: 7,
      appToken: this.appConfig.appToken,
      TimezoneID: moment.tz.guess(),
      Language: userLang || this.appConfig.defaultLang
    };
    const appID = this.gf.guid4();
    return this._http.postUnsecure(`AdHoc/init/${appID}`, postRegister).pipe(
      map((res) => {
        res.appID = appID;
        return res;
      })
    );
  }

  registerAuthID(authID, paymentID, nonce): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          'PaymentMethods/Update3DSecureAuthentication/' +
            paymentID +
            '/' +
            authID +
            '/' +
            nonce
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getToken(currency: string, isAdhoc = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `PaymentMethods/braintree/token/${
            currency || DEFAULT_CURRENCY
          }/${String(isAdhoc)}`
        )
        .subscribe({
          next: (res) => {
            resolve(res);
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }

  checkUnpaid(customerID, unExpired?): Promise<any> {
    //Returns either the unpaid invoice or false
    return new Promise((resolve) => {
      if (!unExpired) {
        this._http.get('Invoices/UnpaidInvoices/' + customerID).subscribe(
          (res) => {
            if (res.length) {
              resolve(res);
            } else {
              resolve(false);
            }
          },
          (err) => {
            resolve(false);
          }
        );
      } else {
        this._http
          .get('Invoices/GetInvoicesByCustomer/' + customerID + '/false')
          .subscribe(
            (res) => {
              if (res.datas && res.datas.length) {
                resolve(res.datas);
              } else {
                resolve(false);
              }
            },
            (err) => {
              resolve(false);
            }
          );
      }
    });
  }

  getCustomerInvoices(customerID, number, startIndex) {
    return this._http.get(
      `Invoices/GetInvoicesByCustomer/${customerID}/true/true/${number}/${startIndex}/true`
    );
  }

  payInvoice(customerID, invoiceID): Promise<any> {
    //Success = Resolves true
    //Reject = Resolves a translation code for PAYMENT_REJECT

    return new Promise((resolve, reject) => {
      this._http
        .get('Invoices/PayInvoice/' + customerID + '/' + invoiceID)
        .subscribe(
          (res) => {
            if (res.success) {
              //Success
              resolve(true);
            } else {
              if (res.datas) {
                if (['Paid', 'PaidFreeTransaction'].includes(res.datas)) {
                  //Success
                  resolve(true);
                } else if (res.datas === 'NoPaymentMethod') {
                  reject('NO_PAYMENT');
                }
              }

              if (
                !res.datas ||
                (res.datas &&
                  !['Paid', 'PaidFreeTransaction', 'NoPaymentMethod'].includes(
                    res.datas
                  ))
              ) {
                //Check latest transaction to see the reason for reject
                this.getInvoiceTransactions(invoiceID).then((transactions) => {
                  if (
                    transactions.length ||
                    (transactions[0].PaymentResultInfos &&
                      transactions[0].PaymentResultInfos.Message)
                  ) {
                    //Braintree reject
                    resolve(
                      transactions[0].PaymentResultInfos.Message.replace(
                        /\s/g,
                        ''
                      )
                    );
                  } else {
                    reject('REJECTED_UNKNOWN');
                  }
                });
              }
            }
          },
          (err) => {
            if (err.errorName === 'invoiceAlreadyPaid') {
              //Success, already paid
              resolve(true);
            } else {
              //API Error
              reject('REJECTED_UNKNOWN');
            }
          }
        );
    });
  }

  getInvoiceTransactions(invoiceID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          'FinancialTransactions/GetFinancialTransactionsByInvoice/' + invoiceID
        )
        .subscribe(
          (res) => {
            if (!res || !res.datas) {
              resolve([]);
            } else {
              res.datas.sort((transaction1, transaction2) => {
                return (
                  transaction2.PK_TransactionID - transaction1.PK_TransactionID
                );
              });
              resolve(res.datas);
            }

            //this.lastTransaction = sortedByID[0];
            //console.log(this.lastTransaction)
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  setPaymentPeriod(paymentPeriod): Promise<any> {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
  getPaymentMethods(customerID): Promise<any> {
    /*
            Gets payment methods for a customer
            - Input:
                - customerID
            - Output:
                - Account with payment added
                - Empty array if not found
        */
    return new Promise((resolve) => {
      this._http.get('PaymentMethods/' + customerID).subscribe(
        (methods) => {
          if (methods && methods.length) {
            resolve(methods);
          } else {
            resolve([]);
          }
        },
        (err) => {
          resolve(false);
        }
      );
    });
  }
  showPrices() {
    this.alertCtrl
      .create({
        header: this.translate.instant('PAYMENT.PRICES'),
        subHeader:
          '<p>' +
          this.translate.instant('PAYMENT.PRICES_ARE_PER_TRANSACTION') +
          `</p>
                <table>
                    <tr><td>&#8226; Visa </td><td> kr 2,50</td></tr>
                    <tr><td>&#8226; Paypal </td><td> kr 2,50</td></tr>
                </table>`,
        cssClass: 'price-popup',
        buttons: ['OK']
      })
      .then((alert) => {
        alert.present();
      });
  }
  setDefault(paymentMethodID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .put('PaymentMethods/SetDefault/' + paymentMethodID, {})
        .subscribe(
          (res) => {
            //FIX: Handle error
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  deleteMethod(paymentMethodID) {
    this._http.delete('PaymentMethods/' + paymentMethodID).subscribe((res) => {
      //FIX: Handle error
    });
  }
  isFree(point, summary?) {
    const priceNow = summary ? summary.PricingNow : {}; //Means it will act as free if not passed

    if (
      point &&
      !point.Cost &&
      !point.StartupCost &&
      !point.CostWhenOccupied &&
      !point.CostChargingTime &&
      !priceNow.HasVariablePricing &&
      !priceNow.ChargingCostKWH &&
      !priceNow.Cost_Charging_Time &&
      !priceNow.Cost_Occupied &&
      !priceNow.FixedStartupCost
    ) {
      return true;
    }
    return false;
  }
  checkPayment(customerID, point?, skipPaymentCheck = false): Promise<any> {
    /*
            Checking if point is free or not.
            If point is not free, check if user has registered a payment method.
            Resolves true if free.
            Rejects with error if no payment or some other payment error
        */
    return new Promise((resolve, reject) => {
      if (skipPaymentCheck) {
        resolve(true);
      } else if (this.isFree(point)) {
        //Free station
        resolve(true);
      } else {
        this._http
          .get('PaymentMethods/hasRegisteredPayment/' + customerID)
          .subscribe(
            (methods) => {
              // braintreevalidation = processor_declined or processor_accepted
              if (methods && methods.ListPayments) {
                methods = methods.ListPayments;

                let defaultPayment = methods.filter(
                  (method) => method.isDefault.toUpperCase() == 'TRUE'
                )[0];
                //let acceptedPayments = methods.filter(method=>method.braintreevalidation==='processor_accepted');

                if (
                  defaultPayment &&
                  defaultPayment.braintreevalidation === 'processor_accepted'
                ) {
                  resolve(true);
                } else if (
                  defaultPayment &&
                  defaultPayment.braintreevalidation === 'processor_declined'
                ) {
                  if (defaultPayment.paymentType === 'PayPal') {
                    reject('paypal_declined');
                  } else {
                    reject('card_declined');
                  }
                } else {
                  reject('no_payment');
                }
              } else {
                reject('no_payment');
              }
            },
            (err) => {
              reject('no_payment');
            }
          );
      }
    });
  }
}

type PaymentMethodFilter =
  | 'All'
  | 'OnlyAdhocPaymentMethods'
  | 'OnlyRecurringPaymentMethods';
