import { NgModule } from '@angular/core';
import { SelectAllDirective } from './select-all.directive';
import { CanSeeDirective } from './can-see.directive';
import { HelpdeskButtonDirective } from './helpdesk-button.directive';
import { LaunchNavigatorDirective } from './launch-navigator.directive';
import { TranslateModule } from '@ngx-translate/core';
import { BarcodeScannerDirective } from './barcode-scanner.directive';
import { AutofillDirective } from './autofill.directive';

@NgModule({
  declarations: [
    AutofillDirective,
    BarcodeScannerDirective,
    CanSeeDirective,
    HelpdeskButtonDirective,
    LaunchNavigatorDirective,
    SelectAllDirective
  ],
  imports: [TranslateModule.forChild()],
  exports: [
    AutofillDirective,
    BarcodeScannerDirective,
    CanSeeDirective,
    HelpdeskButtonDirective,
    LaunchNavigatorDirective,
    SelectAllDirective
  ]
})
export class DirectivesModule {}
