import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnChanges
} from '@angular/core';
import { RolesService } from '../services/roles/roles.service';
import { UserService } from '../services';

@Directive({
  selector: '[canSee]'
})
export class CanSeeDirective implements OnChanges {
  @Input() canSee;
  @Input() canSeeStation; // Can be used to send stationID.
  @Input() canSeePoint; // Can be used to send pointID

  hasView: boolean = false;

  constructor(
    private rolesService: RolesService,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef
  ) {
    this.userService.loggedIn$.subscribe(() => {
      this.checkAccess();
    });
  }

  ngOnChanges() {
    this.checkAccess();
  }

  private async checkAccess(): Promise<void> {
    const hasAccess = await this.rolesService.hasHighlevelAccess(this.canSee);

    if (hasAccess && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!hasAccess && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
