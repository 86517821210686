import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

import dropin from 'braintree-web-drop-in';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TokenService } from 'src/app/services/token/token.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { AppConfig } from 'src/app/variables';

@Component({
  selector: 'sc-add-payment-dropin',
  templateUrl: './add-payment-dropin.component.html',
  styleUrls: ['./add-payment-dropin.component.scss']
})
export class AddPaymentDropinComponent implements OnInit {
  customer: any;
  authPage: boolean = false;
  btToken: string;
  btLanguage: string;
  loading: boolean = true;
  btInstance: any;
  validPayment: boolean = false;
  selectedMethod: string = null;
  decodedKey: any;
  addingMethod: boolean = false;
  @Output() paymentAdded: EventEmitter<any> = new EventEmitter(); //Used for old apps
  appConfig: AppConfig = new AppConfig();

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private storage: Storage, //Used for old apps
    private tokenService: TokenService, //Used for old apps
    private payment: PaymentService //Used for old apps
  ) {}

  ngOnInit() {
    let encodedKey = this.route.snapshot.paramMap.get('key');

    if (encodedKey) {
      this.decodeKey(encodedKey);
    } else {
      window.top.postMessage(
        {
          dropinError: true,
          error: 'Missing key',
          errorCode: 'INVALID_REQUEST'
        },
        '*'
      );
    }
  }
  handleOldApps() {
    //Used for old apps
    this.customer = this.decodedKey.Customer;
    this.authPage = this.decodedKey.AuthPage;
    this.btLanguage = 'en_US';
    this.tokenService.token = this.decodedKey.Token;

    this.storage.set('token', this.decodedKey.Token).then(() => {
      this.payment.getToken('NOK').then(
        (btToken) => {
          this.btToken = btToken;

          this.init();
        },
        (err) => {
          console.log(err);
          this.showError(
            this.translate.instant('PAYMENT.COULD_NOT_ADD'),
            this.translate.instant('PAYMENT_REJECT.INITIATION_ERROR')
          );
        }
      );
    });
  }
  decodeKey(key) {
    let decodedKey = atob(key);
    if (decodedKey) {
      try {
        this.decodedKey = JSON.parse(decodedKey);

        if (this.decodedKey.Token) {
          //Used for old apps
          this.handleOldApps();
        } else {
          this.btToken = this.decodedKey.btToken;
          this.btLanguage = this.decodedKey.btLanguage;
          this.translate.use(this.decodedKey.lang);

          this.customer = this.decodedKey.Customer;

          //this.currency = this.decodedKey.Currency;
          this.authPage = this.decodedKey.AuthPage;

          this.init();
        }
      } catch (err) {
        window.top.postMessage(
          { dropinError: true, error: err, errorCode: 'INVALID_REQUEST' },
          '*'
        );
      }
    }
  }

  selectAnotherMethod() {
    //this.selectedMethod = false;
    this.btInstance.clearSelectedPaymentMethod();
  }
  init() {
    dropin
      .create({
        authorization: this.btToken,
        threeDSecure: true,
        container: '#dropin',
        paypal: {
          flow: 'vault',
          displayName: this.appConfig.title
        },
        card: {
          //To add cardholder name:
          /*cardholderName:{
                    required:true,
                },*/
          overrides: {
            styles: {}
          }
        },
        locale: this.btLanguage
      })
      .then((instance) => {
        window.top.postMessage('frame-loaded', '*');
        this.loading = false;
        this.btInstance = instance;
        instance.on('paymentOptionSelected', (ev) => {
          this.selectedMethod = ev.paymentOption;
          let el =
            this.elem.nativeElement.querySelectorAll('.braintree-toggle');
          this.renderer.listen(el[0], 'click', (ev) => {
            this.selectedMethod = null;
          });
        });

        instance.on('noPaymentMethodRequestable', () => {
          //Can be used to disable the add-button
          this.validPayment = false;
        });
        instance.on('paymentMethodRequestable', (ev) => {
          this.validPayment = true;
          if (this.selectedMethod === 'paypal') {
            this.addPayment();
          }
        });
      })
      .catch((err) => {
        window.top.postMessage(
          { dropinError: true, error: err, errorCode: 'COULD_NOT_INITIATE' },
          '*'
        );
      });
  }
  addPayment() {
    let errorStatuses = [
      'authenticate_error',
      'authenticate_failed',
      'authenticate_signature_verification_failed',
      'authenticate_unable_to_authenticate',
      'authentication_unavailable',
      'lookup_error',
      'challenge_required',
      'authenticate_rejected'
    ];
    let cancelStatus = 'lookup_enrolled';

    let threeDSecureParameters = {
      amount: '0.00',
      email: this.customer.Email || '',
      billingAddress: {
        givenName: this.customer.FirstName || '',
        surname: this.customer.LastName || ''
      }
    };
    let reqOptions: any = {
      threeDSecure: threeDSecureParameters
    };
    this.loading = true;
    this.btInstance.requestPaymentMethod(reqOptions, (tokenizeErr, payload) => {
      this.loading = false;
      //For paypal threeDSecureInfo is not sent

      if (tokenizeErr) {
        //Error adding method
        this.btInstance.clearSelectedPaymentMethod();
        window.top.postMessage(
          {
            dropinError: true,
            error: tokenizeErr,
            errorCode: 'COULD_NOT_REGISTER'
          },
          '*'
        );
      } else if (
        payload.threeDSecureInfo &&
        payload.threeDSecureInfo.status === cancelStatus
      ) {
        //User cancelled
        this.btInstance.clearSelectedPaymentMethod();
        window.top.postMessage('cancel', '*');
      } else if (
        payload.threeDSecureInfo &&
        errorStatuses.includes(payload.threeDSecureInfo.status)
      ) {
        //Error adding. User should try again
        this.btInstance.clearSelectedPaymentMethod();
        window.top.postMessage(
          {
            dropinError: true,
            error: payload.threeDSecureInfo.status,
            errorCode: payload.threeDSecureInfo.status
          },
          '*'
        );
      } else {
        this.addingMethod = true;
        //Success
        window.top.postMessage({ btPayload: payload }, '*');
        if (this.decodedKey.Token) {
          //Used for old apps
          this.createBraintreeCustomer(payload);
        }
      }
    });
  }
  createBraintreeCustomer(payload) {
    //Used for old apps
    this.payment.createBraintreeCustomer(payload, this.customer, 'NOK').then(
      (res) => {
        this.loading = false;
        window.top.postMessage('added', '*');
        this.paymentAdded.emit(true);
      },
      (err) => {
        let errorCode =
          err.datas && err.datas.message ? err.datas.message : err.errorName;
        this.loading = false;

        let message =
          this.translate.instant('APP.TRY_AGAIN_OR_SUPPORT') +
          '\n' +
          this.translate.instant('PAYMENT.ERROR_CODE', { code: errorCode });

        this.showError(
          this.translate.instant('PAYMENT.COULD_NOT_ADD'),
          message
        );
      }
    );
  }
  showError(header, message) {
    //Used for old apps

    this.alertCtrl
      .create({
        header: header, //'Your app needs to be updated',
        message: message, //'Please update your app to be able to add a payment method',
        buttons: [
          {
            text: this.translate.instant('APP.OK'),
            handler: () => {
              this.paymentAdded.emit(false);
              window.top.postMessage('skip', '*');
            }
          }
        ]
      })
      .then((alert) => {
        alert.present();
      });
  }
  doSkip() {
    window.top.postMessage('skip', '*');
  }
}
