import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage-angular';
import { DefaultsService } from '../defaults/defaults.service';
import { HttpClient } from '@angular/common/http';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  token: any;
  refreshTokenPromise: Promise<any>;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private defaults: DefaultsService
  ) {
    this.storage.get('token').then((token) => {
      if (token) this.token = token;
    });
  }

  public refreshToken(reload?): Promise<any> {
    if (!this.refreshTokenPromise) {
      //refreshTokenPromise prevents multiple calls at the same time. All calls will listen to one promise
      this.refreshTokenPromise = new Promise((resolve, reject) => {
        this.storage.get('token').then((token) => {
          if (!reload && token && !helper.isTokenExpired(token)) {
            this.token = token;

            resolve(token);
          } else {
            this.storage.get('rToken').then(
              (rToken) => {
                if (rToken) {
                  this.storage.get('appid').then((appid) => {
                    //appid is created at login
                    //Send appid and rToken to server for new access-token
                    this.storage.get('pushToken').then((pushToken) => {
                      let data = {
                        appID: appid,
                        rToken: rToken,
                        pushToken: pushToken
                      };

                      //If exists, call refresh.
                      this.http
                        .post<any>(
                          this.defaults.baseUrl +
                            'Security/RefreshAccessTokenInternal',
                          data
                        )
                        .subscribe(
                          (newToken) => {
                            newToken = newToken.Result.datas;
                            //If valid response, store tokens
                            if (newToken) {
                              if (!helper.isTokenExpired(newToken)) {
                                this.storage.set('token', newToken);
                                this.token = newToken;
                                resolve(newToken);
                              } else {
                                //The new token is expired, very strange
                                reject(new TokenError(false, 'Invalid token'));
                              }
                            } else {
                              this.storage.remove('rToken');
                              //Refresh token is probably not valid. Need to log in to get a new one
                              reject(
                                new TokenError(true, 'Could not refresh token')
                              );
                            }
                          },
                          (err) => {
                            reject(new TokenError(false, 'Unknown error'));
                          }
                        );
                    });
                  });
                } else {
                  //No token/expired token and no rToken
                  reject(new TokenError(false, 'User not authenticated'));
                }
              },
              (err) => {
                //Error when getting rToken
                reject(
                  new TokenError(
                    false,
                    'Something went wrong. Please try again'
                  )
                );
              }
            );
          }
        });
      }).finally(() => {
        this.refreshTokenPromise = null;
      });
    }
    return this.refreshTokenPromise;
  }
}
export class TokenError {
  prompt_login: boolean;
  message: string;
  constructor(_login, _message) {
    this.prompt_login = _login;
    this.message = _message;
  }
}
