import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { HttpInterceptorService } from 'src/app/services';
import { GlobalsService } from '../globals/globals.service';
import { Storage } from '@ionic/storage-angular';
import { TokenService } from '../token/token.service';
import { PushService } from '../push/push.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppConfig } from 'src/app/variables';
import { BehaviorSubject } from 'rxjs';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: any = {};
  userPromise: Promise<any>;
  language: any = {};
  appConfig: AppConfig = new AppConfig();

  readonly checkForPageAccess = new BehaviorSubject<boolean>(false);
  readonly loggedIn$ = this.checkForPageAccess.asObservable();

  private customerID: any;
  private userID: any;

  constructor(
    private auth: AuthService,
    public _http: HttpInterceptorService,
    private pushService: PushService,
    private gf: GlobalsService,
    private storage: Storage,
    private tokenService: TokenService
  ) {
    this.auth.authenticate().subscribe((isAuthenticated: boolean) => {
      if (isAuthenticated) {
        this.checkForPageAccess.next(true);
      }
    });
  }

  login(username, password, registering?): Promise<any> {
    return new Promise((resolve, reject) => {
      this.pushService.register().then(
        (token) => {
          //FIX: Need to check if user rejects the push, then we need to set the prefs to false
          let appid = this.gf.guid4();

          let data = {
            NotificationToken: token,
            appID: appid,
            Email: username,
            Password: password,
            pushState: this.pushService.state,
            appToken: this.appConfig.appToken
          };

          this._http.postUnsecure('Users/Authenticate', data).subscribe(
            (res) => {
              if (res.user && res.customer) {
                this.pushService.login();
                //Customer holds enough info
                this.storeUser(res.customer);

                this.storeTokens(res.rToken, appid, res.accessToken).then(
                  () => {
                    //Important that the token is stored before the user can go on.

                    //Notify app component to update the tabs
                    this.checkForPageAccess.next(true);

                    resolve(res.customer);
                  }
                );
              } else {
                if (typeof res === 'string') {
                  reject(res);
                } else {
                  reject('default');
                }
              }
            },
            (err) => {
              console.log(err);
              reject('default');
            }
          );
        },
        (err) => {
          reject('default');
          console.log(err);
        }
      );
    });
  }

  loginAbraUser(res: any) {
    return new Promise((resolve, reject) => {
      if (res.user && res.customer) {
        let appid = this.gf.guid4();

        this.pushService.login();
        //Customer holds enough info
        this.storeUser(res.customer);

        this.storeTokens(res.rToken, appid, res.accessToken).then(() => {
          //Important that the token is stored before the user can go on.

          //Notify app component to update the tabs
          this.checkForPageAccess.next(true);

          resolve(res.customer);
        });
      } else {
        reject(res);
      }
    });
  }

  logout() {
    return new Promise((resolve) => {
      this.storage.get('appid').then((appid) => {
        this.getUser().then((user) => {
          if (user && appid) {
            this._http
              .get('Users/Logout/' + user.UserKeys + '/' + appid)
              .subscribe(
                () => {
                  this.clearUser();
                  resolve(true);
                },
                () => {
                  this.clearUser();
                  resolve(true);
                }
              );
          } else {
            this.clearUser();
            resolve(true);
          }
        });
      });
      this.clearUser();
    });
  }
  private clearUser() {
    this.storeUser(null);
    this.tokenService.token = null;
    this.storage.remove('token');
    this.storage.remove('rToken');
    this.storage.remove('appid');
    this.storage.remove('adhoc');
    //this.brandingService.clear()
    //this.pushService.unregisterPush();
  }
  storeUser(user) {
    this.user = user;
    this.checkForPageAccess.next(!!user);
  }
  storeTokens(rToken, appID, accessToken, adhoc?): Promise<any> {
    return new Promise((resolve) => {
      this.storage.set('rToken', rToken);
      this.storage.set('appid', appID);
      if (adhoc) this.storage.set('adhoc', adhoc);
      this.tokenService.token = accessToken;
      this.storage.set('token', accessToken).then((success) => {
        resolve(true);
      });
    });
  }

  async isAdhocUser() {
    return await this.storage.get('adhoc');
  }

  setIsAdhocUser(isAdhoc: boolean) {
    this.storage.set('adhoc', isAdhoc);
  }

  getCustomerID(): Promise<any> {
    return new Promise((resolve) => {
      this.storage.get('token').then((token) => {
        if (token) {
          let decodedToken = helper.decodeToken(token);
          this.customerID = decodedToken.CustomerID;
          resolve(this.customerID);
        } else {
          resolve(0);
        }
      });
    });
  }
  getUserID(): Promise<any> {
    return new Promise((resolve) => {
      this.storage.get('token').then((token) => {
        if (token) {
          let decodedToken = helper.decodeToken(token);
          this.userID = decodedToken.UserID;
          resolve(this.userID);
        } else {
          resolve(0);
        }
      });
    });
  }
  getUser(reload?): Promise<any> {
    if (window.location.href.includes('/add-payment/')) {
      //Old way. This is to handle old apps
      return new Promise((resolve) => resolve(true));
    }
    if (!this.userPromise) {
      //userPromise prevents multiple calls at the same time. All calls will listen to one promise
      this.userPromise = new Promise((resolve) => {
        this.auth.authenticate().subscribe((authenticated) => {
          if (authenticated) {
            if (this.user && this.user.PK_CustomerID && !reload) {
              let decodedToken = helper.decodeToken(this.tokenService.token);
              this.user.Installers =
                String(decodedToken.Installers)?.split(',') || [];
              resolve(this.user);
            } else {
              this.storage.get('token').then(
                (token) => {
                  if (token) {
                    let decodedToken = helper.decodeToken(token);
                    this._http
                      .get('Customers/' + decodedToken.CustomerID)
                      .subscribe(
                        (customer) => {
                          this.user = customer;
                          this.user.Installers =
                            String(decodedToken.Installers)?.split(',') || [];
                          resolve(customer);
                        },
                        (err) => {
                          resolve(false);
                        }
                      );
                  } else {
                    resolve(false);
                  }
                },
                (err) => {
                  resolve(false);
                }
              );
            }
          } else {
            this.clearUser();
            resolve(false);
          }
        });
      }).finally(() => {
        //Clear the promise, so that we call the full function next time
        this.userPromise = null;
      });
    }
    return this.userPromise;
  }
  getAccessCards(): Promise<any> {
    return new Promise((resolve) => {
      this.getCustomerID().then((customerID) => {
        if (customerID) {
          this._http.get('AccessCards/ByCustomer/' + customerID).subscribe(
            (accessCards) => {
              resolve(accessCards);
            },
            (err) => {
              resolve(null);
            }
          );
        } else {
          resolve(null);
        }
      });
    });
  }
  getClaimedPoints(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCustomerID().then((customerID) => {
        if (customerID) {
          this._http
            .get('ChargePoints/GetMyClaimedPoints/' + customerID)
            .subscribe(
              (res) => {
                if (res.datas) {
                  resolve(res.datas);
                } else {
                  //Not sure when this happens
                  resolve([]);
                }
              },
              (err) => {
                reject(err);
              }
            );
        } else {
          //Not logged in
          resolve([]);
        }
      });
    });
  }
  updateUser(customer): Promise<any> {
    return new Promise((resolve) => {
      this._http.put('Customers', customer).subscribe((res) => {
        this.storeUser(customer);
        resolve(true);
      });
    });
  }
}

export interface Page {
  title: string;
  icon: string;
  url: string;
}
