import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { AuthComponent } from 'src/app/components/auth/auth.component';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AppConfig } from 'src/app/variables';
import { Storage } from '@ionic/storage-angular';
import { UserService } from '../user';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  appConfig: AppConfig = new AppConfig();
  constructor(
    private authService: AuthService,
    private modalCtrl: ModalController,
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<boolean>((observer) => {
      forkJoin([
        this.authService.authenticate(),
        from(this.userService.isAdhocUser())
      ]).subscribe(([authenticated, isAdhoc]) => {
        if (
          !authenticated ||
          (isAdhoc &&
            ((state.url.includes('/orders') &&
              !state.url.includes('/orders/invoice')) ||
              state.url.includes('/login')))
        ) {
          this.promptAuth(state.url);
          observer.next(false);
        } else {
          observer.next(true);
        }

        observer.complete();
      });
    });
  }
  private promptAuth(navigateTo: string, adhoc?): Promise<any> {
    return new Promise((resolve) => {
      this.modalCtrl
        .create({
          component: AuthComponent,
          componentProps: {
            showLogin: true,
            adhoc: adhoc
          }
        })
        .then((modal) => {
          modal.onDidDismiss().then((res: OverlayEventDetail) => {
            if (res.data) {
              this.router.navigate([navigateTo]);
            } else {
              //Stay on view if closed
              //this.router.navigate(['stations']);
            }
          });
          modal.present();
        });
    });
  }
}
