import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseInt'
})
export class ParseIntPipe implements PipeTransform {
  transform(value: string | number | null | undefined): number | null {
    if (value === null || value === undefined) {
      return null;
    } else if (typeof value === 'number') {
      return value;
    }

    return Number.parseFloat(value);
  }
}
