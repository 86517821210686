import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'sc-login-alert',
  templateUrl: './login-alert.component.html',
  styleUrls: ['./login-alert.component.scss']
})
export class LoginAlertComponent {
  paymentMethods = [];

  constructor(private modalCtrl: ModalController, private platform: Platform) {}

  close(action: string) {
    this.modalCtrl.dismiss(action);
  }

  platformIs(platform) {
    return this.platform.is(platform);
  }
}
