import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultsService } from '../defaults/defaults.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  constructor(
    private http: HttpClient,
    private storage: Storage,
    private defaults: DefaultsService
  ) {}

  errorLog(message) {
    let date: any = new Date();
    if (typeof message === 'object') {
      message = JSON.stringify(message);
    }
    date =
      date.getDay() +
      '/' +
      date.getMonth() +
      '/' +
      date.getFullYear() +
      '-' +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' : '') +
      date.getMinutes();

    this.storage.get('errorLog').then((res) => {
      if (!res) {
        res = '';
      }
      message = '\n[' + date + '\nERROR:\n' + message + ' ] \n' + res;
      this.storage.set('errorLog', message);
    });
  }
  getId(column, name, api): Promise<any> {
    return new Promise((resolve) => {
      this.http.get<any>(this.defaults.baseUrl + api).subscribe((response) => {
        response = response;
        let id = null;
        for (let i = 0; i < response.length; i++) {
          if (response[i][column].toUpperCase() == name.toUpperCase()) {
            for (let key in response[i]) {
              if (key.substr(0, 2) == 'PK') {
                id = response[i][key];
              }
            }
          }
        }
        resolve(id);
      });
    });
  }
  getDateAsUTC(y?: number) {
    /* Getting current date as UTC
            y = (optional)(int) Years ahead in time.
        */
    y = y ? y : 0; //if y then y else 0
    let date;
    date = new Date();

    //All stuff below is to make it like a nice UTC date
    //Like so: 2016-06-23T13:52:07.193
    date =
      date.getUTCFullYear() +
      y +
      '-' +
      ('00' + (date.getUTCMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getUTCDate()).slice(-2) +
      'T' +
      ('00' + date.getUTCHours()).slice(-2) +
      ':' +
      ('00' + date.getUTCMinutes()).slice(-2) +
      ':' +
      ('00' + date.getUTCSeconds()).slice(-2) +
      '.' +
      date.getUTCMilliseconds();
    return date;
  }
  toTS(date, hour?, min?, sec?) {
    let timestamp;
    if (date) {
      timestamp = new Date(date);
      if (hour && min) {
        timestamp.setHours(hour, min, sec);
      }

      timestamp = timestamp.getTime();
      return timestamp;
    } else {
      return '';
    }
  }
  guid4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  convertHex(hexCode, opacity) {
    var hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    var r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }
}
