import { HttpInterceptorService } from './http-interceptor/http-interceptor.service';
import { DefaultsService } from './defaults/defaults.service';
import { AuthService } from './auth/auth.service';
import { TokenService } from './token/token.service';
import { AnalyticsService } from './analytics/analytics.service';
import { AuthAsService } from './auth-as/auth-as.service';
//import { BrandingService } from './branding/branding.service';
import { GlobalsService } from './globals/globals.service';
import { LanguageService } from './language/language.service';
import { LoadingService } from './loading/loading.service';
import { PushService } from './push/push.service';
import { UserService } from './user/user.service';
import { ChargingService } from './charging/charging.service';
import { PaymentService } from './payment/payment.service';
import { DistanceService } from './distance/distance.service';
import { LocationService } from './location/location.service';
import { FilterService } from './filter/filter.service';
import { StationsService } from './stations/stations.service';
import { WebsocketService } from './websocket/websocket.service';
import { AuthGuard } from './auth-guard/auth-guard.service';
import { PointService } from './point/point.service';
import { SupportService } from './support/support.service';
import { PlatformService } from './platform/platform.service';
import { SpotpriceService } from './spotprice/spotprice.service';
import { AbraAuthService } from './abraAuth/abra-auth.service';

//All services below will be provided in app.module
export const Services = [
  AnalyticsService,
  AuthService,
  AuthAsService,
  //BrandingService,
  DefaultsService,
  GlobalsService,
  HttpInterceptorService,
  LanguageService,
  LoadingService,
  PushService,
  TokenService,
  UserService,
  ChargingService,
  PaymentService,
  DistanceService,
  LocationService,
  FilterService,
  StationsService,
  WebsocketService,
  AuthGuard,
  PointService,
  SupportService,
  PlatformService,
  SpotpriceService,
  AbraAuthService
];
