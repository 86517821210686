<div class="ion-padding">
  <div class="ion-text-center" *ngIf="loading || addingMethod">
    <ion-spinner name="dots"></ion-spinner>
  </div>

  <div id="dropin" *ngIf="!addingMethod"></div>
  <ion-button
    class="submit-button"
    (click)="addPayment()"
    *ngIf="
      (!loading && selectedMethod && selectedMethod !== 'paypal') ||
      addingMethod
    "
    expand="block"
    [disabled]="!validPayment"
    type="submit">
    {{ 'AUTH.REGISTER_PAYMENT' | translate }}
  </ion-button>

  <div class="ion-text-center ion-padding-top">
    <a
      href="https://www.braintreegateway.com/merchants/2b8tv22q2hkjsrtd/verified"
      target="_blank">
      <img
        src="https://s3.amazonaws.com/braintree-badges/braintree-badge-wide-light.png"
        width="280px"
        height="44px"
        border="0" />
    </a>
  </div>
  <ion-button
    expand="block"
    fill="clear"
    class="ion-margin-top"
    *ngIf="authPage"
    (click)="doSkip()">
    {{ 'AUTH.SKIP' | translate }}
  </ion-button>
</div>
