export type UserTypeStrings = keyof typeof UserType;
export enum UserType {
  EV_Driver = 0,
  /**Demo user */
  DemoUser = 1 << 0,
  /**api access? */
  ExternalAPIAccess = 1 << 1,
  /**Support access? */
  Support = 1 << 2,
  /**Has access to all the boxes of one box type */
  BoxManufacturer = 1 << 3,
  /**Can integrate boxes in meshcrafts? */
  IntegrationTest = 1 << 4,
  /**Is an operator */
  Operator = 1 << 5,
  /**/ //  */
  Installer = 1 << 6,
  /**/ //  */
  Other7 = 1 << 7,
  /**/ //  */
  Other8 = 1 << 8,
  /**/ //  */
  Other9 = 1 << 9,
  /**/ //  */
  Other10 = 1 << 10,
  /**/ //  */
  Other11 = 1 << 11,
  Other12 = 1 << 12,
  Other13 = 1 << 13,
  Other14 = 1 << 14,
  Other15 = 1 << 15,
  Other16 = 1 << 16,
  OwnsCompany = 1 << 17,
  FinancialsCurrent = 1 << 18,
  CanEditUsers = 1 << 19,
  /**not stored in database, just set when loging in */
  OwnsStations = 1 << 20,
  Programmer = Support |
    Operator |
    OwnsStations |
    EV_Driver |
    DemoUser |
    ExternalAPIAccess |
    BoxManufacturer |
    IntegrationTest |
    Installer |
    Other7 |
    Other8 |
    Other9 |
    Other10 |
    Other11 |
    Other12 |
    Other13 |
    Other14 |
    Other15 |
    Other16 |
    OwnsCompany |
    FinancialsCurrent |
    CanEditUsers,
  Admin = (1 << 21) | Programmer,
  SuperUser = (1 << 22) | Admin
}
