import { Injectable } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { HttpInterceptorService } from '../http-interceptor/http-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class BoxRequestService {
  private lastRequest: any;

  constructor(private _http: HttpInterceptorService) {}

  startWait(requestID, duration = 10, intervalSeconds = 1): Promise<any> {
    return new Promise((resolve, reject) => {
      //Will send empty response if error in the http-request or if user aborts when prompted
      if (!requestID) return;

      const checkInterval = interval(intervalSeconds * 1000);
      const subscribe: Subscription = checkInterval.subscribe((i) => {
        //Check response by interval
        this.checkStatus(requestID).then(
          (success) => {
            if (success) {
              resolve(true);
              subscribe.unsubscribe();
              return;
            } //else pending
          },
          (err) => {
            reject(err);
            subscribe.unsubscribe();
            return;
          }
        );

        const seconds = i * intervalSeconds;
        if (seconds >= duration) {
          //Timeout

          reject('TIMEOUT_BOX');
          subscribe.unsubscribe();
          return;
        }
      });
    });
  }
  private checkStatus(requestID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getResponsesByRequestID(requestID).then(
        (res) => {
          for (let i = 0; i < res.Boxes.length; i++) {
            let box = res.Boxes[i];
            if (box.HasFailed) {
              //Failed
              if (box.answer && box.answer.status) {
                reject(box.answer.status.toUpperCase());
              } else {
                reject(box.answer);
              }

              break;
            }
            if (!box.answer) {
              resolve(false);
              break;
              //Pending
            }
            if (i === res.Boxes.length - 1) {
              resolve(true);
              //Success
            }
          }
        },
        (err) => {
          reject('apiError');
        }
      );
    });
  }
  private getResponsesByRequestID(requestID): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get('Commands/GetCommandRequest/' + requestID).subscribe(
        (res) => {
          res = this.handleReqResponse(res);
          this.lastRequest = res;
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  private handleReqResponse(res) {
    res.totalAnswers = this.calcAnswers(res.Boxes);
    return res;
  }
  private calcAnswers(boxes) {
    if (!boxes) return;
    if (!boxes.length) return 0;
    let answers = 0;
    boxes.forEach((box) => {
      if (box.answer) answers++;
    });

    return answers;
  }
}
