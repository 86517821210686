import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { LoginAlertComponent } from './login-alert.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    TranslateModule.forChild(),
    PipesModule
  ],
  declarations: [LoginAlertComponent],
  exports: [LoginAlertComponent]
})
export class LoginAlertModule {}
